import styled from 'styled-components';


const Popup = styled.div<{ visible: boolean, position?: string }>`
  position: absolute;
  width: 240px;
  max-width: 240px;
  border-radius: 10px !important;
  padding: 10px 15px 10px 12px !important;
  ${({ position }) => 
    position === "right"
    ? `
      left: calc(100% + 15px);
      transform: translateY(-50%);
      top: 50%;
    `
    : `
      bottom: calc(100% + 15px);
      left: 50%;
      transform: translateX(-50%);
    `
  }
  background-color: #fff;
  color: #000;
  border-radius: 5px;
  font-size: 14px;
  white-space: wrap;
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transition: opacity 0.3s ease;
  z-index: 100;
  box-shadow: 1px -1px 6px 0px rgba(0, 0, 0, 0.07);

  white-space: normal;
  word-wrap: break-word;
  max-width: 240px;

  &::after {
    content: '';
    position: absolute;
    ${({ position }) => 
      position === "right"
      ? `
        left: -16px;
        top: 42%;
        border-color: transparent white transparent transparent;
        border-width: 8px;
        border-style: solid;
      `
      : `
        top: 100%; /* Position at the bottom of the popup */
        left: 50%;
        transform: translateX(-50%);
        border-left: 8px solid transparent; /* Transparent left side */
        border-right: 8px solid transparent; /* Transparent right side */
        border-top: 8px solid white; /* White top triangle */
        border-color: #fff transparent transparent transparent;
      `
    }
    width: 0;
    height: 0;
    filter: drop-shadow(0.5px 0.5px 1px rgba(0,0,0,0.07));
  }
`;

export default Popup;