import styled from "styled-components";
import { Colors } from "../../../../../../styles/themeNew";
import { Select } from "../../../../../../componentsNew/Select";
import Checkbox from "../../../../../../components/shared/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import {
  mSelector,
  mSubSelector,
  nSelector,
  nSubSelector,
  setM,
  setMSub,
  setN,
  setNSub,
  setT,
  setTSub,
  tSelector,
  tSubSelector,
} from "../../../../redux/clinicalData";
import Popup from "./Popup";
import { useState } from "react";

export const TnmClassification = () => {

  const [isTnmNonApplicableActive, setTnmNonApplicableActive] = useState(false);
  const [isTnmUnknownActive, setTnmUnknownActive] = useState(false);
  
  const dispatch = useDispatch();

  const _t = useSelector(tSelector);
  const _tSub = useSelector(tSubSelector);

  const _n = useSelector(nSelector);
  const _nSub = useSelector(nSubSelector);

  const _m = useSelector(mSelector);
  const _mSub = useSelector(mSubSelector);

  const ts = ["TX", "T0", "Tis", "T1", "T2", "T3", "T4"];
  const subs = ["a", "b", "c", "-"];

  const ns = ["NX", "N0", "N1", "N2", "N3"];
  const ms = ["MX", "M0", "M1", "M2", "M3"];

  const [isHoveredTumour, setIsHoveredTumour] = useState(false);
  const [isHoveredNode, setIsHoveredNode] = useState(false);
  const [isHoveredMetastatic, setIsHoveredMetastatic] = useState(false);

  const popupContentTumour = (
    <>
      <TTitle>Размер опухоли:</TTitle>
      <TContent>
        <b>TX</b> – первичную опухоль нельзя оценить
        <br />
        <b>T0</b> – нет первичной опухоли
        <br />
        <b>Tis</b> – карцинома in situ (рак в пределах слизистой)
        <br />
        <b>T1</b> – <b>T4</b> увеличение размеров первичной опухоли и/или локального распространения
      </TContent>
    </>
  );

  const popupContentNode = (
    <>
      <TTitle>Вовлечение лимфатических узлов:</TTitle>
      <TContent>
        <b>NX</b> – региональные лимфатические узлы нельзя оценить
        <br />
        <b>N0</b> – нет метастазов в региональные лимфатические узлы
        <br />
        <b>N1</b> – <b>N3</b> есть метастазы в региональные лимфатические узлы
      </TContent>
    </>
  );

  const popupContentMetastatic = (
    <>
    <TTitle>Наличие отдаленных метастазов:</TTitle>
    <TContent>
      <b>MX</b> – наличие отдалeнных метастазов невозможно оценить
      <br />
      <b>M0</b> – отсутствие отдалeнных метастазов
      <br />
      <b>M1</b> – наличие отдалeнных метастазов
    </TContent>
    </>
  )

  return (
    <StyledContainer>
      <StyledRowContainer>
        <StyledTitleContainer>
          Классификация TNM <StyledAsteriskContainer>*</StyledAsteriskContainer>
        </StyledTitleContainer>
      </StyledRowContainer>

      <StyledContainer2>
        <StyledContainer3
          onMouseEnter={() => setIsHoveredTumour(true)}
          onMouseLeave={() => setIsHoveredTumour(false)}
        >Tumour<Popup visible={isHoveredTumour}>{popupContentTumour}</Popup></StyledContainer3>
        
        <StyledContainer3
          onMouseEnter={() => setIsHoveredNode(true)}
          onMouseLeave={() => setIsHoveredNode(false)}
        >Node<Popup visible={isHoveredNode}>{popupContentNode}</Popup></StyledContainer3>
        <StyledContainer3
          onMouseEnter={() => setIsHoveredMetastatic(true)}
          onMouseLeave={() => setIsHoveredMetastatic(false)}
        >Metastatic spread<Popup visible={isHoveredMetastatic}>{popupContentMetastatic}</Popup></StyledContainer3>
      </StyledContainer2>

      <StyledSelectsContainer>
        <StyledSelectContainer>
          <Select
            placeholder="T"
            options={ts.map((t) => ({
              label: t,
              value: t,
              selected: t === _t?.value,
            }))}
            onSelect={(option) => {
              dispatch(setT(option));
            }}
          />
        </StyledSelectContainer>

        <StyledSelectContainer>
          <Select
            placeholder="sub"
            options={subs.map((sub) => ({
              label: sub,
              value: sub,
              selected: sub === _tSub?.value,
            }))}
            onSelect={(option) => {
              dispatch(setTSub(option));
            }}
          />
        </StyledSelectContainer>

        <StyledSelectContainer>
          <Select
            placeholder="N"
            options={ns.map((n) => ({
              label: n,
              value: n,
              selected: n === _n?.value,
            }))}
            onSelect={(option) => {
              dispatch(setN(option));
            }}
          />
        </StyledSelectContainer>

        <StyledSelectContainer>
          <Select
            placeholder="sub"
            options={subs.map((sub) => ({
              label: sub,
              value: sub,
              selected: sub === _nSub?.value,
            }))}
            onSelect={(option) => {
              dispatch(setNSub(option));
            }}
          />
        </StyledSelectContainer>

        <StyledSelectContainer>
          <Select
            placeholder="M"
            options={ms.map((m) => ({
              label: m,
              value: m,
              selected: m === _m?.value,
            }))}
            onSelect={(option) => {
              dispatch(setM(option));
            }}
          />
        </StyledSelectContainer>

        <StyledSelectContainer>
          <Select
            placeholder="sub"
            options={subs.map((sub) => ({
              label: sub,
              value: sub,
              selected: sub === _mSub?.value,
            }))}
            onSelect={(option) => {
              dispatch(setMSub(option));
            }}
          />
        </StyledSelectContainer>
      </StyledSelectsContainer>
      <TCheckboxWr>
        <TCheckbox checked={isTnmNonApplicableActive} label='Неприменимо' onChange={() => setTnmNonApplicableActive(!isTnmNonApplicableActive)} />
        <TCheckbox checked={isTnmUnknownActive} label='Не установлена' onChange={() => setTnmUnknownActive(!isTnmUnknownActive)} />
      </TCheckboxWr>
    </StyledContainer>
  );
};

const StyledContainer = styled.div``;

const StyledContainer2 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  color: ${Colors.MONOCHROM[400]};
  font-size: 16px;
  font-weight: 400;

  width: 100%;
  height: 60px;

  border-radius: 6px;
  border: 1px solid ${Colors.MONOCHROM[200]};
  margin-bottom: 4px;
`;

const StyledContainer3 = styled.div`
  position: relative;
  width: calc(100% / 3);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  :hover {
   cursor: pointer;
  }
`;

const StyledRowContainer = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;

  gap: 16px;
`;

const StyledTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  color: ${Colors.MONOCHROM[950]};
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 4px;

  gap: 4px;
`;

const StyledAsteriskContainer = styled.div`
  color: ${Colors.RED[500]};
  font-size: 12px;
  font-weight: 400;
`;

const StyledSelectsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 4px;
`;

const StyledSelectContainer = styled.div`
  width: calc(100% / 3);
`;

export const TTitle = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
`;

export const TContent = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
`;

export const TCheckboxWr = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 6px;
` 
export const TCheckbox = styled(Checkbox)<{ disabled?: Boolean }>`
  display: flex;
  flex-direction: row;
  width: max-content;
  padding: 6px 0px;
  border-radius: 10px;
  box-sizing: border-box;
  cursor: ${({ disabled }) => !disabled? 'pointer': 'not-allowed'};
    span {
      cursor: ${({ disabled }) => !disabled? 'pointer': 'not-allowed'};
    }
  margin-right: 30px;
`;