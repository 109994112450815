export const BellIcon = () => {
    return (
        <svg width="88" height="88" viewBox="0 0 88 88" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="88" height="88" rx="44" fill="#EEF3FF"/>
            <path d="M43.5447 27C37.5612 27 32.6985 31.9431 32.6985 38.0255V43.3361C32.6985 44.457 32.2285 46.166 31.6681 47.1215L29.5893 50.6313C28.3058 52.7996 29.1916 55.2069 31.5416 56.0154C39.3328 58.6615 47.7386 58.6615 55.5297 56.0154C57.7171 55.2804 58.6751 52.6526 57.4821 50.6313L55.4032 47.1215C54.8609 46.166 54.3909 44.457 54.3909 43.3361V38.0255C54.3909 31.9615 49.5101 27 43.5447 27Z" stroke="#3E6AFF" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
            <path d="M47 27C46.4973 26.8571 45.9784 26.746 45.4432 26.6825C43.8865 26.4921 42.3946 26.6032 41 27C41.4703 25.8254 42.6378 25 44 25C45.3622 25 46.5297 25.8254 47 27Z" stroke="#3E6AFF" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M49 58C49 60.75 46.75 63 44 63C42.6333 63 41.3667 62.4333 40.4667 61.5333C39.5667 60.6333 39 59.3667 39 58" stroke="#3E6AFF" stroke-width="2" stroke-miterlimit="10"/>
        </svg>

    );
};
