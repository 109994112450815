import styled from "styled-components";

import { Button } from "../../../../componentsNew/Button";
import { currentStepSelector, setCurrentStep } from "../../redux/currentStep";
import { useDispatch, useSelector } from "react-redux";
// import { useCreate } from "../../hooks/useCreate";
import { steps } from "../..";
import { laboratorySelector } from "../../redux/laboratory";
import { editedPatientSelector } from "../../redux/patient";
// import {
//   doctorSelector,
//   dateSelector as referrerDateSelector,
// } from "../../redux/referrer";
import { setCreateQuestionModalActive } from "../../redux/common";
import { researchTypeSelector } from "../../redux/researchType";

export const ControlButtons = () => {
  const dispatch = useDispatch();

  const currentStep = useSelector(currentStepSelector);

  const researchType = useSelector(researchTypeSelector);

  const selectedLaboratory = useSelector(laboratorySelector);

  const editedPatient = useSelector(editedPatientSelector);

  // const doctor = useSelector(doctorSelector);
  // const date = useSelector(referrerDateSelector);

  // const { createIhcReferral } = useCreate();

  const onBack = () => {
    const currentStepIndex = steps.findIndex(
      (step) => step.code === currentStep.code
    );

    if (currentStepIndex >= 0) {
      const previousStep = steps.find(
        (_, index) => index === currentStepIndex - 1
      );

      if (previousStep) {
        dispatch(
          setCurrentStep({
            code: previousStep.code,
            name: previousStep.name,
          })
        );
      }
    }
  };

  const onCancel = () => {};

  const onContinue = async () => {
    if (currentStep.code === "MARKERS") {
      dispatch(setCreateQuestionModalActive(true));
      // await createIhcReferral();
      return;
    }

    const currentStepIndex = steps.findIndex(
      (step) => step.code === currentStep.code
    );

    if (currentStepIndex >= 0) {
      const nextStep = steps.find((_, index) => index === currentStepIndex + 1);

      if (nextStep) {
        dispatch(
          setCurrentStep({
            code: nextStep.code,
            name: nextStep.name,
          })
        );
      }
    }
  };

  const checkContinueButtonDisabled = () => {
    if (currentStep.code === "RESEARCH_TYPE" && !researchType) {
      return true;
    }

    if (currentStep.code === "LABORATORY" && !selectedLaboratory) {
      return true;
    }

    if (currentStep.code === "PATIENT" && !editedPatient) {
      return true;
    }

    return false;
  };

  return (
    <StyledControlButtonsWrapper>
      <StyledControlButtonsContainer>
        <StyledControlButtonContainer>
          <Button text="Назад" type="SECONDARY" onClick={onBack} />
        </StyledControlButtonContainer>

        <StyledControlButtonContainer>
          <Button text="Отмена" type="SECONDARY" onClick={onCancel} />
        </StyledControlButtonContainer>

        <StyledControlButtonContainer>
          <Button
            text="Далее"
            type="PRIMARY"
            onClick={onContinue}
            disabled={checkContinueButtonDisabled()}
          />
        </StyledControlButtonContainer>
      </StyledControlButtonsContainer>
    </StyledControlButtonsWrapper>
  );
};

// const StyledBackButton = styled.div``;

const StyledControlButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  /* position: sticky; */
  /* bottom: 0; */
  /* height: 200px; */
  background-color: white;

  padding: 32px 0 64px 0;
  margin-top: 200px;

  width: 696px;

  position: fixed;
  bottom: 0;
  z-index: 2;
`;

const StyledControlButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 24px;
`;

const StyledControlButtonContainer = styled.div`
  width: calc(100% / 3);
`;
