import styled from "styled-components";
import { Textarea } from "../../../../../../componentsNew/Textarea";
import { useDispatch, useSelector } from "react-redux";
import {
  setTreatment,
  treatmentSelector,
} from "../../../../redux/clinicalData";
import Checkbox from "../../../../../../components/shared/Checkbox";
import { useState } from "react";

export const Treatment = () => {
  const dispatch = useDispatch();

  const [isStageNonApplicableActive, setStageNonApplicableActive] = useState(false);
  const [isStageUnknownActive, setStageUnknownActive] = useState(false);


  const treatment = useSelector(treatmentSelector);
  const popupText = "Вид лечения, его сроки, дозировка лекарственного препарата, доза облучения"

  return (
    <StyledContainer>
      <Textarea
        title="Предоперационное лечение"
        required={true}
        placeholder="Свободный ввод"
        value={treatment || ""}
        infoicon={true}
        popupText={popupText}
        onChange={(event) => {
          dispatch(setTreatment(event.target.value));
        }}
      />
      <TCheckboxWr>
        <TCheckbox checked={isStageNonApplicableActive} label='Неприменимо' onChange={() => setStageNonApplicableActive(!isStageNonApplicableActive)} />
        <TCheckbox checked={isStageUnknownActive} label='Не установлена' onChange={() => setStageUnknownActive(!isStageUnknownActive)} />
      </TCheckboxWr>
    </StyledContainer>
  );
};

const StyledContainer = styled.div``;

export const TCheckboxWr = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 6px;
` 
export const TCheckbox = styled(Checkbox)<{ disabled?: Boolean }>`
  display: flex;
  flex-direction: row;
  width: max-content;
  padding: 6px 0px;
  border-radius: 10px;
  box-sizing: border-box;
  cursor: ${({ disabled }) => !disabled? 'pointer': 'not-allowed'};
    span {
      cursor: ${({ disabled }) => !disabled? 'pointer': 'not-allowed'};
    }
  margin-right: 30px;
`;