import React, {useCallback} from "react";

import { ROUTES } from "../../../../constants/routes";
import { useNavigate } from "react-router-dom";
// import {useReactToPrint} from "react-to-print";
// import { useSelector } from "react-redux";
import styled from "styled-components";


type Props = {
    referralNumber: string;
};

export const CreateSuccessModal = (props: Props) => {
    const navigate = useNavigate();
    // const patient = useSelector(state => state);
    // const handlePrint = useReactToPrint({
    //     documentTitle: `${props.referralNumber} Направление на молекулярное профилирование`,
    //     content: () => printReferralRef.current,
    // }) ?? (() => null);
    const onCreateReferral = useCallback(() => {
        navigate(ROUTES.REFERRALS, { replace: true });
    }, [navigate]);
    return (
        <StyledContainer>
            <StyledIconContainer>
                <svg
                    width="44"
                    height="44"
                    viewBox="0 0 44 44"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M22 42C33 42 42 33 42 22C42 11 33 2 22 2C11 2 2 11 2 22C2 33 11 42 22 42Z"
                        stroke="#05B44D"
                        strokeWidth="1.8"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M14 22L19.3271 27L30 17"
                        stroke="#05B44D"
                        strokeWidth="1.8"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            </StyledIconContainer>
            <StyledText>
                {`Направление №${props.referralNumber} успешно создано`}
            </StyledText>
            <StyledButtonContainer>
                <StyledButton onClick={() => onCreateReferral()}>Ок</StyledButton>
                <StyledPrintButton onClick={() => console.log("Print clicked")}>
                    Распечатать
                </StyledPrintButton>
            </StyledButtonContainer>
        </StyledContainer>
    );
};

const StyledContainer = styled.div`
  position: relative;
  left: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  padding: 20px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 400px;
  -ms-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`;

const StyledIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  background: #E0F0E0;
  border-radius: 50%;
`;

const StyledText = styled.div`
  font-size: 16px;
  text-align: center;
  color: #333;
`;

const StyledButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  justify-content: center;
`;

const StyledButton = styled.button`
  padding: 10px 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background: #fff;
  cursor: pointer;
  font-size: 14px;
  flex: 1;

  &:hover {
    background: #f0f0f0;
  }
`;

const StyledPrintButton = styled(StyledButton)`
  background: #05b44d;
  color: #fff;
  border: none;

  &:hover {
    background: #049a3b;
  }
`;
