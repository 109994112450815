import { useDispatch, useSelector } from "react-redux";
import { useHttpClient } from "../../../hooks/useHttpClient";
import {
  ReferralsService,
} from "../../../services/Referrals";
import { laboratorySelector } from "../redux/laboratory";
import { editedPatientSelector } from "../redux/patient";
import {
  dateSelector as referrerDateSelector,
  doctorSelector,
} from "../redux/referrer";
import {
  additionalClinicalDataSelector,
  clinicalDiagnosisSelector,
  mSelector,
  mSubSelector,
  nSelector,
  nSubSelector,
  previousResultsSelector,
  selectedDiagnosisSelector,
  selectedLocalizationSelector,
  stageSelector,
  // subSelector,
  treatmentSelector,
  tSelector,
  tSubSelector,
} from "../redux/clinicalData";
import {
  dateSelector as biomaterialRegistrationDateSelector,
  // materialSelector,
  materialTypeSelector,
  obtainingSelector,
  sampleTypeSelector,
} from "../redux/biomaterialRegistration";
import { markersSelector } from "../redux/markers";
import {
  setCreatedReferralNumber,
  setCreateSuccessModalActive,
} from "../redux/common";

export const useCreate = () => {
  const dispatch = useDispatch();

  const httpClient = useHttpClient();

  const laboratory = useSelector(laboratorySelector);

  const editedPatient = useSelector(editedPatientSelector);

  const doctor = useSelector(doctorSelector);

  const referrerDate = useSelector(referrerDateSelector);

  const selectedLocalization = useSelector(selectedLocalizationSelector);

  const t = useSelector(tSelector);
  const tSub = useSelector(tSubSelector);

  const n = useSelector(nSelector);
  const nSub = useSelector(nSubSelector);

  const m = useSelector(mSelector);
  const mSub = useSelector(mSubSelector);

  const stage = useSelector(stageSelector);
  // const sub = useSelector(subSelector);

  const additionalClinicalData = useSelector(additionalClinicalDataSelector);

  const previousResults = useSelector(previousResultsSelector);

  const treatment = useSelector(treatmentSelector);

  const referralsService = new ReferralsService(httpClient);

  // const material = useSelector(materialSelector);

  const materialType = useSelector(materialTypeSelector);

  const selectedDiagnosis = useSelector(selectedDiagnosisSelector);

  const clinicalDiagnosis = useSelector(clinicalDiagnosisSelector);

  const obtaining = useSelector(obtainingSelector);
  const sampleType = useSelector(sampleTypeSelector);

  const biomaterialRegistrationDate = useSelector(
    biomaterialRegistrationDateSelector
  );

  const markers = useSelector(markersSelector);

  const createIhcReferral = async () => {
    if (!laboratory?.id) {
      return;
    }

    if (!editedPatient) {
      return;
    }

    if (!editedPatient.ambulatoryCard) {
      return;
    }

    if (!doctor) {
      return;
    }

    const result = await referralsService.createIhcReferral({
      laboratory: laboratory.id,

      patient: {
        ulid: editedPatient?.ulid || "",
        country: editedPatient?.countryId,
        address: editedPatient?.address || "",
        gender: editedPatient?.gender || "",
        last_name: editedPatient?.lastName || "",
        first_name: editedPatient?.firstName || "",
        middle_name: editedPatient?.patronymic || "",
        oms_number: editedPatient?.oms || "",
        snils_number: editedPatient?.snils || "",
        date_of_birth: editedPatient?.birthdate || "",
      },

      ambulatory_card: {
        organization: editedPatient.ambulatoryCard.organizationId as number,
        custom_organization:
          editedPatient.ambulatoryCard.organizationName || "",
        number: editedPatient.ambulatoryCard.number as string,
      },

      doctor: {
        ulid: doctor.ulid,
        organization: doctor.user_profile.organization,
        last_name: doctor.user_profile.last_name,
        first_name: doctor.user_profile.first_name,
        middle_name: doctor.user_profile.middle_name,
      },

      referral_date: referrerDate as string,
      localization: selectedLocalization?.value
        ? parseInt(selectedLocalization?.value, 10)
        : 0,
      icd_10: selectedDiagnosis?.value
        ? parseInt(selectedDiagnosis?.value, 10)
        : 0,

      clinical_diagnosis: clinicalDiagnosis || "",
      t_category: t?.value || "",
      t_sub: tSub?.value || "",
      n_category: n?.value || "",
      n_sub: nSub?.value || "",
      m_category: m?.value || "",
      m_sub: mSub?.value || "",
      tnm_non_applicable: false,
      tnm_unknown: false,
      stage: stage?.value || "",
      stage_non_applicable: false,
      stage_unknown: false,
      additional_info: additionalClinicalData as string,
      additional_info_non_applicable: false,
      additional_info_unknown: false,
      previous_analysis_results: previousResults as string,
      previous_analysis_results_non_applicable: false,
      previous_analysis_results_unknown: false,
      pre_ambulatory_care: treatment as string,
      pre_ambulatory_care_non_applicable: false,
      pre_ambulatory_care_unknown: false,

      bio: {
        material_type: materialType as string,
        method_of_acquisition: obtaining as string,
        sample_type: sampleType as string,
        date_of_acquisition: biomaterialRegistrationDate as string,
        // blocks_and_slides: {
        //   organization: 4;
        //   blocks: [
        //     { organ: "Печень"; cypher: "12345678-09" },
        //     { organ: "Лёгкое"; cypher: "12345678-09" }
        //   ];
        //   slides: [
        //     { organ: "Печень"; cypher: "12345678-09" },
        //     { organ: "Лёгкое"; cypher: "12345678-09" }
        //   ];
        // };
      },

      markers: markers.map((marker) => marker.id),
    });

    if (result?.success) {
      dispatch(setCreateSuccessModalActive(true));
      dispatch(setCreatedReferralNumber(result?.referral));
    }
  };

  return {
    createIhcReferral,
  };
};
