import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Colors } from "../../../../../../styles/themeNew";
import { ArrowRight } from "./icons/Arrow";
import { MeatballsMenuDefault } from "./icons/MeatballsMenu";
import { AddBlockIcon } from "./icons/AddBlock";
import { RemoveBlockIcon } from "./icons/RemoveBlock";
import { ClearBlockIcon } from "./icons/ClearBlock";
import { CopyBlockIcon } from "./icons/CopyBlock";

type Props = {
    // title?: string;
    // placeholder?: string;
    // required?: boolean;
    // options: Option[];
    // onSelect: (option: Option) => void;
    // disabled?: boolean;
};

export const HistologicalMaterial: React.FC<Props> = (props) => {
    const [blocks, setBlocks] = useState<{ block: string; glass: string }[]>([{ block: "", glass: "" }]);
    const [glasses, setGlasses] = useState<{ block: string; glass: string }[]>([{ block: "", glass: "" }]);
    const [popupVisible, setPopupVisible] = useState<{ block: number | null; glass: number | null }>({ block: null, glass: null });
    const [selectedInput, setSelectedInput] = useState<"block" | "glass">("block");
    const popupRef = useRef<HTMLDivElement>(null);

    const handleSelect = (option: "block" | "glass") => {
        setSelectedInput(option);
    };

    const handleAddItem = (type: "block" | "glass") => {
        if (type === "block") {
            setBlocks([...blocks, { block: "", glass: "" }]);
        } else {
            setGlasses([...glasses, { block: "", glass: "" }]);
        }
        setPopupVisible({ block: null, glass: null });
    };

    const handleRemoveItem = (type: "block" | "glass", index: number) => {
        if (type === "block") {
            setBlocks(blocks.filter((_, i) => i !== index));
        } else {
            setGlasses(glasses.filter((_, i) => i !== index));
        }
        setPopupVisible({ block: null, glass: null });
    };

    const handleClearItem = (type: "block" | "glass", index: number) => {
        if (type === "block") {
            const newBlocks = [...blocks];
            newBlocks[index] = { block: "", glass: "" };
            setBlocks(newBlocks);
        } else {
            const newGlasses = [...glasses];
            newGlasses[index] = { block: "", glass: "" };
            setGlasses(newGlasses);
        }
        setPopupVisible({ block: null, glass: null });
    };

    const handleCopyItem = (type: "block" | "glass", index: number) => {
        if (type === "block") {
            const newBlocks = [...blocks];
            newBlocks.push(newBlocks[index]);
            setBlocks(newBlocks);
        } else {
            const newGlasses = [...glasses];
            newGlasses.push(newGlasses[index]);
            setGlasses(newGlasses);
        }
        setPopupVisible({ block: null, glass: null });
    };

    const handleItemChange = (type: "block" | "glass", index: number, field: "block" | "glass", value: string) => {
        if (type === "block") {
            const newBlocks = [...blocks];
            newBlocks[index][field] = value;
            setBlocks(newBlocks);
        } else {
            const newGlasses = [...glasses];
            newGlasses[index][field] = value;
            setGlasses(newGlasses);
        }
    };

    const renderPopup = (type: "block" | "glass", index: number) => {
        return (
            <StyledPopup ref={popupRef} isGlass={type === "glass"}>
                <StyledPopupItem onClick={() => handleAddItem(type)}>{type === "glass" ? "Добавить Гист. стекло к данному блоку" : "Добавить блок"} <AddBlockIcon /></StyledPopupItem>
                <StyledPopupItem onClick={() => handleRemoveItem(type, index)}>{type === "glass" ? "Удалить Гист. стекла" : "Удалить блок"} <RemoveBlockIcon /></StyledPopupItem>
                <StyledPopupItem onClick={() => handleClearItem(type, index)}>Очистить <ClearBlockIcon /></StyledPopupItem>
                <StyledPopupItem onClick={() => handleCopyItem(type, index)}>Скопировать <CopyBlockIcon /></StyledPopupItem>
            </StyledPopup>
        );
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
                setPopupVisible({ block: null, glass: null });
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [popupRef]);

    return (
        <StyledContainer>
            <TTabPanel>
                <StyledHistologicalBtn
                    onClick={() => handleSelect('block')}
                    selected={selectedInput === 'block'}
                >1. Блоки *</StyledHistologicalBtn>
                <StyledHistologicalBtn
                    onClick={() => handleSelect('glass')}
                    selected={selectedInput === 'glass'}
                >2. Гист. стекла *</StyledHistologicalBtn>
            </TTabPanel>
            {selectedInput === "block" && blocks.map((block, index) => (
                <StyledBlockContainer key={index}>
                    <StyledInput
                        type="text"
                        placeholder="--"
                        value={block.block}
                        onChange={(e) => handleItemChange("block", index, "block", e.target.value)}
                    />
                    <ArrowRight />
                    <StyledInput
                        type="text"
                        placeholder="Номер"
                        value={block.glass}
                        onChange={(e) => handleItemChange("block", index, "glass", e.target.value)}
                    />
                    <StyledOptionsButtonContainer>
                        <StyledOptionsButton onClick={() => setPopupVisible({ ...popupVisible, block: index })}>
                            <MeatballsMenuDefault />
                        </StyledOptionsButton>
                        {popupVisible.block === index && renderPopup("block", index)}
                    </StyledOptionsButtonContainer>
                </StyledBlockContainer>
            ))}
            {selectedInput === "glass" && glasses.map((glass, index) => (
                <StyledBlockContainer key={index}>
                    <StyledInput
                        type="text"
                        placeholder="--"
                        value={glass.block}
                        onChange={(e) => handleItemChange("glass", index, "block", e.target.value)}
                    />
                    <ArrowRight />
                    <StyledInput
                        type="text"
                        placeholder="Номер"
                        value={glass.glass}
                        onChange={(e) => handleItemChange("glass", index, "glass", e.target.value)}
                    />
                    <StyledOptionsButtonContainer>
                        <StyledOptionsButton onClick={() => setPopupVisible({ ...popupVisible, glass: index })}>
                            <MeatballsMenuDefault />
                        </StyledOptionsButton>
                        {popupVisible.glass === index && renderPopup("glass", index)}
                    </StyledOptionsButtonContainer>
                </StyledBlockContainer>
            ))}
        </StyledContainer>
    );
};

const StyledHistologicalBtn = styled.div<{
    selected?: boolean;
}>`
  display: flex;
  width: 260px;
  height: 45px;
  border: 1px solid rgb(216, 219, 223);
  box-sizing: border-box;
  border-radius: 10px;
  background-color:  ${(props) => props.selected ? '#CBECFE' : 'white'};
  justify-content: center;
  align-items: center;
`;

const TTabPanel = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: center;
  height: 60px;
  width:100%;
  border: 1px solid ${Colors.MONOCHROM[200]};
  box-sizing: border-box;
  border-radius: 10px;
  background-color: white;
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  gap: 10px;
`;

const StyledBlockContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  position: relative;
`;

const StyledInput = styled.input`
  flex: 1;
  padding: 10px;
  border: 1px solid ${Colors.MONOCHROM[200]};
  font-size: 14px;
`;

const StyledOptionsButtonContainer = styled.div`
  position: relative;
`;

const StyledOptionsButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: ${Colors.MONOCHROM[950]};
`;

const StyledPopup = styled.div<{
    isGlass: boolean;
}>`
  position: absolute;
  top: 0px;
  left: 0;
  background: white;
  width:  ${(props) => props.isGlass ? '370px' : '260px'};
  border: 1px solid ${Colors.MONOCHROM[200]};
  border-radius: 5px;
  z-index: 10;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
`;

const StyledPopupItem = styled.div`
  padding: 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  &:hover {
    background: ${Colors.BRAND[50]};
  }
`;
