type Props = {
  type: "search" | "logo" | "info" | "info-colored";
};

export const Icon = (props: Props) => {
  if (props.type === "search") {
    return (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9 3.5C5.96243 3.5 3.5 5.96243 3.5 9C3.5 12.0376 5.96243 14.5 9 14.5C10.519 14.5 11.893 13.8852 12.8891 12.8891C13.8852 11.893 14.5 10.519 14.5 9C14.5 5.96243 12.0376 3.5 9 3.5ZM2 9C2 5.13401 5.13401 2 9 2C12.866 2 16 5.13401 16 9C16 10.6625 15.4197 12.1906 14.4517 13.3911L17.7803 16.7197C18.0732 17.0126 18.0732 17.4874 17.7803 17.7803C17.4874 18.0732 17.0126 18.0732 16.7197 17.7803L13.3911 14.4517C12.1906 15.4197 10.6625 16 9 16C5.13401 16 2 12.866 2 9Z"
          fill="#8D94A3"
        />
      </svg>
    );
  }

  if (props.type === "logo") {
    return (
      <svg 
        width="28" 
        height="30" 
        viewBox="0 0 28 30" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
      >
        <path 
          fill-rule="evenodd" 
          clip-rule="evenodd" 
          d="M14.8855 3.35198C8.37927 3.35198 3.10495 8.56695 3.10495 14.9999C3.10495 21.4329 8.37927 26.6479 14.8855 26.6479C18.8904 26.6479 22.4301 24.6727 24.5603 21.6479C24.6501 21.5203 24.6431 21.35 24.5464 21.2275L22.9574 19.214C22.9065 19.1494 22.9058 19.0591 22.9558 18.9938L24.6946 16.7242C24.7675 16.629 24.9121 16.6288 24.9853 16.7236L27.5174 20.0017C28.0704 20.7025 28.1776 21.6994 27.6847 22.5182C25.0914 26.826 20.3308 29.7179 14.8855 29.7179C6.66446 29.7179 0 23.1284 0 14.9999C0 6.87143 6.66446 0.281982 14.8855 0.281982C20.3308 0.281982 25.0914 3.17385 27.6847 7.48169C28.1776 8.30042 28.0704 9.29733 27.5174 9.99813L20.1744 19.3031L20.1596 19.3207C18.9058 20.8156 17.0079 21.772 14.8855 21.772C11.1028 21.772 8.03634 18.74 8.03634 14.9999C8.03634 11.2598 11.1028 8.22787 14.8855 8.22787C17.0079 8.22787 18.9058 9.18423 20.1596 10.6792L20.1744 10.6968L21.6011 12.5047L24.5464 8.77233C24.6431 8.64982 24.6501 8.47952 24.5603 8.35199C22.4301 5.32718 18.8904 3.35198 14.8855 3.35198ZM19.6319 14.9999L17.756 12.6229C17.0668 11.8103 16.0374 11.2979 14.8855 11.2979C12.8176 11.2979 11.1413 12.9553 11.1413 14.9999C11.1413 17.0445 12.8176 18.702 14.8855 18.702C16.0374 18.702 17.0668 18.1895 17.7561 17.377L19.6319 14.9999Z" 
          fill="white" 
        />
      </svg>
    );
  }

  if (props.type === "info") {
    return (
      <svg 
        width="16" 
        height="16" 
        viewBox="0 0 16 16" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle 
          cx="7.99929" 
          cy="7.99978" 
          r="6.77273" 
          stroke="#8D94A3"
        />
        <line 
          x1="8.19531" 
          y1="7.28662" 
          x2="8.19531" 
          y2="11.1351" 
          stroke="#8D94A3" 
          stroke-linecap="round"
        />
        <circle 
          cx="7.99669" 
          cy="4.96934" 
          r="0.606061" 
          fill="#8D94A3"
        />
      </svg> 
    );
  }

    if (props.type === "info-colored") {
      return (
        <svg 
          width="16" 
          height="16" 
          viewBox="0 0 16 16" 
          fill="none" 
          xmlns="http://www.w3.org/2000/svg"
        >
          <g 
            clip-path="url(#clip0_10618_15733)"
          >
          <circle 
            cx="7.99929" 
            cy="8.00002" 
            r="6.77273" 
            stroke="#3E6AFF"
          />
          <line 
            x1="8.19922" 
            y1="7.28699" 
            x2="8.19922" 
            y2="11.1355" 
            stroke="#3E6AFF" 
            stroke-linecap="round"
          />
          <circle 
            cx="7.99669" 
            cy="4.96971" 
            r="0.606061" 
            fill="#3E6AFF"
          />
          </g>
          <defs>
            <clipPath id="clip0_10618_15733">
              <rect width="16" height="16" fill="white"/>
            </clipPath>
          </defs>
        </svg>
      );
    }

  return null;
};
